// This script displays a preloader on page load
window.addEventListener('load', () => {
    const preloader = document.querySelector('.preloader'); // Select the preloader element

    // Use setTimeout to delay showing the preloader by 1500 milliseconds (1.5 seconds)
    setTimeout(() => {
        preloader.classList.add('show-preloader'); // Add the 'show-preloader' class to display the preloader
        document.body.classList.remove('scrollbar-none'); // Remove the 'scrollbar-none' class to enable scrolling on the body
    }, 1500);
});
