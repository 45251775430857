// Initialize Fancybox for elements with the data attribute 'data-fancybox'
function initializeFancybox() {
    $('[data-fancybox]').fancybox({
        // Set the animation effect for opening and closing Fancybox
        animationEffect: "slide",
        // Set the transition effect between slides
        transitionEffect: "circular",
        // Enable looping through the gallery
        loop: true,
        // Define the buttons available in the Fancybox toolbar
        buttons : [ 
            'zoom',        // Button to zoom in and out
            'download',    // Button to download the current image
            'slideShow',   // Button to start a slideshow
            'fullScreen',  // Button to toggle full screen mode
            'thumbs',      // Button to show thumbnail images
            'close'        // Button to close Fancybox
        ]
    });
}

// Initialize Fancybox initially
initializeFancybox();
