// Select the .scroll-back element
const scrollToTop = document.querySelector('.scroll-to-top');

// Set the minimum scroll height to show the scroll-to-top button
const scrollThreshold = 300;

// Function to handle scrolling
function handleScroll() {
    if (window.scrollY > scrollThreshold) {
        scrollToTop.classList.add("active");
    } else {
        scrollToTop.classList.remove("active");
    }
}

// Add a scroll event listener to trigger the function
window.addEventListener('scroll', handleScroll);
