// Select the necessary DOM elements
const hamburger = document.querySelector('.hamburger'); // The hamburger menu button
const sidebar = document.querySelector('.sidebar'); // The sidebar element
const sidebarOverlay = document.querySelector('.sidebar-overlay'); // The overlay behind the sidebar

// Function to close the sidebar
const closeSidebar = () => {
    hamburger.classList.remove('active'); // Remove 'active' class from hamburger button
    document.body.classList.add('clear-padding'); // Add 'clear-padding' class to body to adjust padding
    sidebar.classList.remove('show'); // Remove 'show' class from sidebar to hide it
    sidebar.classList.add('close'); // Add 'close' class to sidebar for closing animation
};

// Function to open the sidebar
const openSidebar = () => {
    hamburger.classList.add('active'); // Add 'active' class to hamburger button
    document.body.classList.remove('clear-padding'); // Remove 'clear-padding' class from body
    sidebar.classList.add('show'); // Add 'show' class to sidebar to display it
    sidebar.classList.remove('close'); // Remove 'close' class from sidebar
};

// Function to handle window resize events
const handleResize = () => {
    if (window.innerWidth > 769) {
        openSidebar(); // Open the sidebar if the window width is greater than 769px
        sidebarOverlay.classList.remove('d-block'); // Hide the sidebar overlay
    } else {
        closeSidebar(); // Close the sidebar if the window width is 769px or less
        sidebarOverlay.classList.remove('d-block'); // Hide the sidebar overlay
    }
};

// Add an event listener for window resize to handle responsive behavior
window.addEventListener('resize', handleResize);

// Add an event listener for clicks on the hamburger button to toggle the sidebar
hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('active'); // Toggle 'active' class on hamburger button
    document.body.classList.toggle('clear-padding'); // Toggle 'clear-padding' class on body
    sidebar.classList.toggle('show'); // Toggle 'show' class on sidebar
    sidebar.classList.toggle('close'); // Toggle 'close' class on sidebar

    if (window.innerWidth < 769) {
        sidebarOverlay.classList.toggle('d-block'); // Toggle 'd-block' class on sidebar overlay if the window width is less than 769px
    }
});

// Initial call to handle resize to set the correct state on page load
handleResize();
