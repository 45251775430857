// This function initializes the Typed.js library with custom options.
// It takes an HTML element, an array of strings to type, and optional typing and backspacing speeds.
// The 'loop' parameter determines whether the typing animation should loop.
function initializeTyped(element, strings, typeSpeed = 50, backSpeed = 50, loop = true) {
    const options = {
        strings,
        typeSpeed,
        backSpeed,
        loop,
    };
    return new Typed(element, options);
}

// Initialize Typed.js on an element with the ID 'profession-name' with an array of strings to type.
initializeTyped('#profession-name', ['Fullstack Developerem', 'Web Developerem']);

// Initialize Typed.js on an element with the ID 'marker-text' with a single string.
// The animation won't loop, and both typing and backspacing speeds are set to 50.
initializeTyped('#marker-text', ['Do każdej świadczonej przeze mnie usługi podchodzę z pełnym zaangażowaniem, pracując z pasją.'], 50, 50, false);
