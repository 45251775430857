// Create a dataLayer array if it doesn't already exist
window.dataLayer = window.dataLayer || [];

// Define the gtag function to push arguments to the dataLayer
function gtag(){dataLayer.push(arguments);}

// Initialize Google Analytics with the current date
gtag('js', new Date());

// Configure Google Analytics with your tracking ID
gtag('config', 'G-X0T3F56Y0R');
