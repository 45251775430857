// Select all elements with the class 'js-download-cv'
const downloadCV = document.querySelectorAll('.js-download-cv');

// Define a function to show an alert when the element is clicked
function showAlert(element, message) {
    element.addEventListener('click', () => {
        alert(message); // Display the alert message when clicked
    }); 
}

// Attach the showAlert function to each downloadCV element
downloadCV.forEach((item) => {
    showAlert(item, 'CV Update. Please try again later...');
});
